<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
        <div class="subpage-content">

        <section>
          <div class="content-band">
          <div class="pagehead-title">
            
            <h1>Size &amp; Spacing<br>Utilities</h1>
            <div class="pagehead-link">
              <a href=""><img src="/html-components/images/svg/link.svg" alt=""/></a>
            </div>
          </div>
            <p>
              Size &amp; spacing utilities can be used to apply height, width, margin and padding to elements.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
        <div class="container max-w1000">

          <section id="height">
            <div class="content-band">
              
              <h2>Height</h2>
              <p>
                Utilities for setting the height, max-height and min-height of an element
              </p>

               <div class="flex flex-col max-w800 white mt-20">

                <div class="flex gray-250">
                  <div class="container plr-30 ptb-20 basis-50">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-50">Properties</div>
                </div>

                <div class="container max-h300 overflow-scroll">
            

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Height</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.height-full</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 100%;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.height-inherit</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: inherit;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.height-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: auto;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 50px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 60px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 70px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 80px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 90px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 100px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h200</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 200px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h300</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 300px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h400</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 400px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h500</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 500px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h600</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 600px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h700</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 700px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.h800</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">height: 800px;</div>
                  </div>

                  

                  <!-- MAX HEIGHT -->

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Max-Height</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 50px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 60px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 70px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 80px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 90px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 100px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h200</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 200px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h300</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 300px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h400</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 400px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h500</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 500px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h600</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 600px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h700</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 700px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-h800</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-height: 800px;</div>
                  </div>


                  <!-- Min Height -->

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Min-Height</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 50px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 60px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 70px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 80px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 90px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 100px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h200</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 200px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h300</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 300px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h400</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 400px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h500</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 500px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h600</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 600px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h700</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 700px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-h800</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-height: 800px;</div>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section id="width">
            <div class="content-band">
              <h2>Width</h2>
              <p class="mb-30">
                Utilities for setting the width, max-width and min-width of an element
              </p>

               <div class="flex flex-col max-w800 white mt-20">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-50 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-50 gray-250">Properties</div>
                </div>
                
                <!-- width -->

                <div class="container max-h300 overflow-scroll">
            

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Width</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.width-full</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 100%;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.width-inherit</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: inherit;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.width-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: auto;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 50px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 60px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 70px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 80px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 90px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 100px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w200</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 200px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w300</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 300px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w400</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 400px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w500</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 500px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w600</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 600px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w700</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 700px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.w800</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">width: 800px;</div>
                  </div>



                  <!-- max width -->

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Max-Width</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 50px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 60px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 70px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 80px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 90px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 100px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w200</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 200px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w300</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 300px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w400</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 400px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w500</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 500px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w600</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 600px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w700</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 700px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.max-w800</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">max-width: 800px;</div>
                  </div>

                  <!-- MIN Width -->

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Min-Width</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 50px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 60px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 70px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 80px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 90px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 100px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w200</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 200px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w300</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 300px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w400</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 400px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w500</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 500px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w600</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 600px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w700</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 700px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.min-w800</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">min-width: 800px;</div>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section id="margin">
            <div class="content-band">
              <h2>Margin</h2>
              <p class="mb-30">
                Utilities for setting the margin of an element
              </p>

               <div class="flex flex-col max-w800 white mt-20">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-50 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-50 gray-250">Properties</div>
                </div>

                <div class="container max-h300 overflow-scroll">
            

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: auto;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.m-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin: 60px;</div>
                  </div>
                  
                 <!-- MT --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin Top</div> 

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: auto;</div>
                  </div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mt-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 60px;</div>
                  </div>

                  <!-- Margin Bottom --> 

                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin Bottom</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: auto;</div>
                  </div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mb-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-bottom: 60px;</div>
                  </div>

                  <!-- Margin Left --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin Left</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: auto;</div>
                  </div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ml-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 60px;</div>
                  </div>

                  <!-- Margin Right --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin Right</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: auto;</div>
                  </div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mr-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-right: 60px;</div>
                  </div>

                  <!--Margin Top-Bottom--> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin Top-Bottom</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: auto; margin-bottom: auto;</div>
                  </div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 0; margin-bottom: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 5px; margin-bottom: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 10px; margin-bottom: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 20px; margin-bottom: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 30px; margin-bottom: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 40px; margin-bottom: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 50px; margin-bottom: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mtb-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-top: 60px; margin-bottom: 60px;</div>
                  </div>

                  
                  <!-- Margin Left-Right --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Margin Left-Right</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-auto</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: auto; margin-right: auto; </div>
                  </div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 0; margin-right: 0; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 5px; margin-right: 5px; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 10px; margin-right: 10px; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 20px; margin-right: 20px; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 30px; margin-right: 30px; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 40px; margin-right: 40px; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 50px; margin-right: 50px; </div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.mlr-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">margin-left: 60px; margin-right: 60px; </div>
                  </div>

                  
                  
                </div>
              </div>
            </div>
          </section>

          <section id="padding">
            <div class="content-band" >
              <h2>Padding</h2>
              <p class="mb-30">
                Utilities for setting the padding of an element
              </p>

               <div class="flex flex-col max-w800 white mt-20">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-50 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-50 gray-250">Properties</div>
                </div>

                <div class="container max-h300 overflow-scroll">
            

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.p-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding: 60px;</div>
                  </div>
                  
                 <!-- PT --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding Top</div> 

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pt-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 60px;</div>
                  </div>

                  <!-- PB --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding Bottom</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pb-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-bottom: 60px;</div>
                  </div>

                  <!-- PL --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding Left</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pl-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 60px;</div>
                  </div>

                  <!-- MR --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding Right</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.pr-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-right: 60px;</div>
                  </div>

                  <!-- PTB --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding Top / Bottom</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 0; padding-bottom: 0;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 5px; padding-bottom: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 10px; padding-bottom: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 20px; padding-bottom: 0px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 30px; padding-bottom: 0px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 40px; padding-bottom: 0px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 50px; padding-bottom: 0px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.ptb-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-top: 60px; padding-bottom: 0px;</div>
                  </div>

                  <!-- PLR --> 
                 <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Padding Left / Right</div>

                 <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-0</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 0; padding-right: 0; </div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 5px; padding-right: 5px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 10px; padding-right: 10px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 20px; padding-right: 20px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 30px; padding-right: 30px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 40px; padding-right: 40px;</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 50px; padding-right: 50px;</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.plr-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">padding-left: 60px; padding-right: 60px;</div>
                  </div>

                  
                </div>
              </div>
            </div>
          </section>


      </div>

        <StickyTOC />

      </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "SizeSpacing",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>

<style></style>
